@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&family=Staatliches&family=Ubuntu:wght@300;400;500;700&display=swap");

@import "antd/dist/antd.css";

body {
  font-family: "Ubuntu", monospace, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  margin: 0px;
  font-weight: 400;
  height: 100%;
}
.root {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
  font-size: inherit;
}
:root {
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
}

h1 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -2px;
}
h2 {
  font-size: 2.8rem;
  line-height: 1.3;
}
h3 {
  font-size: 2.6rem;
}
h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

p,
a {
  text-decoration: none;
  font-size: 1.2rem;
}

button {
  cursor: pointer;
}

@media (max-width: 578px) {
  h2 {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}
.ant-modal-wrap {
  overflow: hidden;
}

@keyframes ring {
  0% {
    height: 50px;
    width: 80px;
    opacity: 1;
  }
  100% {
    height: 80px;
    width: 110px;
    opacity: 0;
  }
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes location {
  0% {
    transform: scale(0.8);
  }
  45% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
